import * as React from 'react';

function Poll(props) {
  return (
    <svg
      width="220"
      height="185"
      viewBox="0 0 220 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9996 171.79H43.5996C46.9133 171.79 49.5996 169.104 49.5996 165.79L49.5996 6.00005C49.5996 2.68634 46.9133 4.57764e-05 43.5996 4.57764e-05H15.9996C12.6859 4.57764e-05 9.99962 2.68634 9.99962 6.00005L9.99962 165.79C9.99962 169.104 12.6859 171.79 15.9996 171.79Z"
        fill="#002a3a"
      />
      <path
        d="M1.00002 184.27L219 184.27C219.552 184.27 220 183.822 220 183.27V181.27C220 180.718 219.552 180.27 219 180.27L1.00002 180.27C0.447723 180.27 1.52588e-05 180.718 1.52588e-05 181.27V183.27C1.52588e-05 183.822 0.447723 184.27 1.00002 184.27Z"
        fill="#002a3a"
      />
      <path
        d="M69.4605 171.79H97.0605C100.374 171.79 103.061 169.104 103.061 165.79L103.061 52.2C103.061 48.8863 100.374 46.2 97.0605 46.2H69.4605C66.1468 46.2 63.4605 48.8863 63.4605 52.2L63.4605 165.79C63.4605 169.104 66.1468 171.79 69.4605 171.79Z"
        fill="#78BE20"
      />
      <path
        d="M176.4 171.79H204C207.314 171.79 210 169.104 210 165.79L210 52.2C210 48.8863 207.314 46.2 204 46.2H176.4C173.086 46.2 170.4 48.8863 170.4 52.2V165.79C170.4 169.104 173.086 171.79 176.4 171.79Z"
        fill="#78BE20"
      />
      <path
        d="M122.939 171.79H150.539C153.853 171.79 156.539 169.104 156.539 165.79V91.89C156.539 88.5763 153.853 85.89 150.539 85.89H122.939C119.625 85.89 116.939 88.5763 116.939 91.89V165.79C116.939 169.104 119.625 171.79 122.939 171.79Z"
        fill="#002a3a"
      />
    </svg>
  );
}

export default Poll;
